<template>
  <div>
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <div class="flex space-x-2 inner">
            <div class="mt-2">설문기간</div>
            <DxDateBox
              :edit-enabled="false"
              :styling-mode="stylingMode"
              width="110"
              height="30"
              v-model="searchType.customTypes.dayStart"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy.MM.dd"
              dateOutOfRangeMessage="시작일은 종료일보다 작거나 같아야 합니다."
              :max="searchType.customTypes.dayEnd"
              maxLength="10"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <div class="mt-1">~</div>
            <DxDateBox
              :styling-mode="stylingMode"
              width="110"
              height="30"
              v-model="searchType.customTypes.dayEnd"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy.MM.dd"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              maxLength="10"
              :min="searchType.customTypes.dayStart"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
          </div>
        </div>
      </div>

      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
    </div>
  </div>
</template>

<script>
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import validationEngine from 'devextreme/ui/validation_engine';
  import {formatDate, getPastFromToday, isSuccess} from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxDateBox,
      DxButton,

      DxRequiredRule,
      DxValidator,
    },
    props: {},
    watch: {},
    data() {
      return {
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(3, 'months'),
            dayEnd: getPastFromToday(-1, 'months'),
          },
          paramsData: null,
        },
        codes: {
          surProcessCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        dataGrid: {
          keyExpr: 'id',
          refName: 'personalSurvey',
          showMenuColumns: true, //컬럼 표시
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색
          hoverStateEnabled: true, //마우스커서 행 강조
          dataSource: [],
          showActionButtons: {},
          customEvent: {
            cellHoverChanged: true,
            cellPrepared: false,
          },
          callApi: 'CALL_EWM_API',
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          scrolling: {
            // 미사용시 주석처리
            mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
          },
          remoteOperations: {
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'No',
              dataField: 'surOrd',
              alignment: 'center',
              width: 40,
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
            },
            {
              caption: '설문제목',
              dataField: 'surNm',
              alignment: 'left',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowSorting: false,
            },
            {
              caption: '설문기간',
              dataField: 'surDt',
              alignment: 'center',
              visible: true,
              width: 220,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: true,
              calculateCellValue: rowData => {
                return `${rowData.surStartDt} ~ ${rowData.surEndDt}`;
              },
            },
            {
              caption: '진행상태',
              dataField: 'surProcessCd',
              alignment: 'center',
              visible: true,
              width: 170,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: true,
              calculateSortValue: data => this.$_enums.ewm.surProcess.values.find(e => data.surProcessCd == e.value).label,
              lookup: {
                dataSource: this.$_enums.ewm.surProcess.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '참여율',
              dataField: 'partiRate',
              alignment: 'center',
              width: 110,
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                return `${rowData.partiRate}%`;
              },
            },
            {
              caption: '결과공개여부',
              dataField: 'resultOpenFl',
              alignment: 'center',
              width: 120,
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              calculateSortValue: data => this.$_enums.common.stringUsedFlag.values.find(e => data.resultOpenFl == e.value).label,
              calculateDisplayCellValue: rowData => {
                if (rowData.resultOpenFl == this.$_enums.common.stringUsedFlag.YES.value) {
                  return `공개`;
                } else {
                  return `비공개`;
                }
              },
            },
            {
              caption: '참여정보',
              dataField: 'partiStatus',
              alignment: 'center',
              width: 120,
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              // lookup: {
              //     dataSource: this.$_getCode('ewm_edu_education_process'),
              //     displayExpr: 'codeNm',
              //     valueExpr: 'id',
              // },
            },
            {
              caption: '설문참석',
              dataField: 'surveyAttend',
              alignment: 'center',
              width: 100,
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              cellTemplate: (container, options) => {
                const isVisible =
                  options.data.surProcessCd === this.$_enums.ewm.surProcess.PROGRESS.value ||
                  (options.data.surProcessCd === this.$_enums.ewm.surProcess.PROGRESS_COMPLETE.value &&
                    options.data.partiStatus == '제출완료' &&
                    options.data.resultOpenFl == this.$_enums.common.stringUsedFlag.YES.value)
                    ? true
                    : false;

                if (isVisible) {
                  let Button = new DxButton({
                    propsData: {
                      text: '',
                      icon: 'search',
                      elementAttr: { class: 'white light_filled txt_S' },
                      value: options.data,
                      onClick: () => {
                        if (options.data.surProcessCd === this.$_enums.ewm.surProcess.PROGRESS.value) {
                          vm.onSurveyAttend(options.data);
                        } else if (options.data.surProcessCd === this.$_enums.ewm.surProcess.PROGRESS_COMPLETE.value) {
                          if (
                            options.data.partiStatus == '제출완료' &&
                            options.data.resultOpenFl == this.$_enums.common.stringUsedFlag.YES.value
                          ) {
                            vm.onSurveyResult(options.data);
                          }
                        }
                      },
                    },
                  });
                  Button.$mount();
                  container.append(Button.$el);
                }
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description : 설문 */
      async onSurveyAttend(data) {
        if (await this.$_Confirm('설문에 참석 하시겠습니까?', { title: `<h2>진행정보</h2>` })) {
          let params = { data: data };
          this.$store.commit('setDetailParams', params);
          this.$router.push({ path: '/ewm/personal/survey/survey-detail' });
        }
      },
      /** @description : 설문 */
      async onSurveyResult(data) {
        console.log(data);
        if (await this.$_Confirm('설문 결과를 확인 하시겠습니까?', { title: `<h2>진행정보</h2>` })) {
          let params = {
            id: data.id,
            route: 'personal',
          };
          this.$store.commit('setDetailParams', params);
          this.$router.push({ path: '/ewm/surveys/implementation/detail' });
        }
      },
      onChangeSearchDay() {
        const regDt = this.searchType.customTypes.dayStart + '000000' + '~' + this.searchType.customTypes.dayEnd + '235959';
        this.searchType.paramsData = { ...this.searchType.paramsData, regDt };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.surDt) {
          return formatDate(rowData.surDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList() {
        if (!validationEngine.validateGroup('validationSearch').isValid) {
          return;
        }

        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            //let params = vm.$_getDxDataGridParam(loadOptions);

            vm.searchType.paramsData['frSurDt'] = vm.searchType.customTypes.dayStart;
            vm.searchType.paramsData['toSurDt'] = vm.searchType.customTypes.dayEnd;

            const params = vm.searchType.paramsData;

            const payload = {
              actionname: 'EWM_PERSONAL_SURVEY',
              data: params,
              loading: false,
              useErrorPopup: true,
            };

            const res = await vm.CALL_EWM_API(payload);

            if (isSuccess(res)) {
              let data = res.data.data;

              data.forEach((d, index) => {
                d.surOrd = index + 1;
                d.partiStatus = d.partiStatus > 0 ? '제출완료' : '미참석';
              });
              const rtnData = {
                data: data,
                totalCount: res.data.header.totalCount,
              };
              vm.$refs.personalSurvey.totalCount = rtnData.totalCount;
              return rtnData;
            } else {
              return () => {
                throw 'Data Loading Error';
              };
            }
          },
        });
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        //this.codeMap = await this.$_getCodeMapList('root_ewm_sur_process');
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;

        this.initCodeMap().then(() => {
          //this.codes.surProcessCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_sur_process'], 2);
          //this.$refs.personalSurvey.getGridInstance.columnOption('surProcessCd', 'lookup', this.codes.surProcessCd);
        });
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.searchType.paramsData = null;
        this.onChangeSearchDay();

        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>

<style scoped>
  .sub_new_style01 .page_search_box .inner div {
    display: inline-block;
  }
  .sub_new_style01 .page_search_box .inner > div {
    vertical-align: middle;
    margin-right: 10px;
  }
  .page_search_box .inner .box-btn-search {
    margin-right: 15px;
  }
</style>
